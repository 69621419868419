<template>
  <Page page-title="Team Management" customClass="is-team mt-6" :hasRightBar="false">

    <!-- Row -->
    <Columns>
      <Column customClass="is-justify-content-flex-end is-flex">
        <router-link v-if="can('team-create')" to="/add" class="button is-link">
          Add Member
        </router-link>
      </Column>
    </Columns>
    <!-- Row -->
    <!-- Row -->
    <Columns>
      <Column>
        <Loading v-if="loading"/>
        <Member v-for="user in users" :key="user.id" :user="user">
          <a href="#" class="px-3" title="Edit">
            <unicon name="pen" fill="black"/>
          </a>
          <a href="#" class="px-3" :title="user.enabled ? 'Active' : 'Inactive'" @click.prevent>
            <unicon name="circle" :fill="user.enabled ? '#8fd14f' : '#ff0000'" icon-style="monochrome"/>
          </a>
          <a href="#" class="px-3" title="Delete" @click.prevent="toggleModal(user.id)">
            <unicon name="trash-alt" fill="black"/>
          </a>
        </Member>
        <ModalCard :modal-title="'Confirmation'" v-if="isOpenedModal">
          <template v-slot:content>
            Are you sure to delete ?
          </template>
          <template v-slot:button-close>
            <button class="delete" aria-label="close" @click="toggleModal"></button>
          </template>
          <template v-slot:button-primary>
            <button class="button is-success" @click="handleDelete">OK</button>
          </template>
          <template v-slot:button-secondary>
            <button class="button" @click="toggleModal">Cancel</button>
          </template>
        </ModalCard>
      </Column>
    </Columns>
    <!-- Row -->
  </Page>
</template>

<script>
import Page from '@/components/layout/Page.vue'
// import Section from '../components/ds/Section.vue'
import Columns from '@/components/ds/Columns.vue'
import Column from '@/components/ds/Column.vue'
import Member from './team/Index.vue'
import {fetchAllUsers, useUser} from "@/use/useUser";
import {defineAsyncComponent, ref} from "vue";
import ModalCard from "@/components/ds/ModalCard";
import {useMutation} from "@vue/apollo-composable";
import {DELETE_USER_MUTATION} from "@/graphql/mutations/userMutation";

export default {
  components: {
    ModalCard,
    Page,
    Columns,
    Column,
    Member,
    Loading: defineAsyncComponent(() => import('@/components/general/Loading.vue')),
  },

  setup() {
    const {can} = useUser()
    const {users, loading, refetch} = fetchAllUsers()
    const isOpenedModal = ref(false)
    const selectedId = ref('')

    const toggleModal = (id) => {
      isOpenedModal.value = !isOpenedModal.value
      selectedId.value = id
    }

    const {mutate: deleteUser, onDone} = useMutation(DELETE_USER_MUTATION, {
      clientId: 'cmsClient'
    })

    const handleDelete = () => {
      deleteUser({id: selectedId.value})
      toggleModal()
    }

    onDone(() => {
      refetch()
    })

    return {
      users,
      loading,
      can,
      isOpenedModal,
      toggleModal,
      handleDelete
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
